
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import KothingEditor from "kothing-editor";
import kothingConfig from "../config/editorConfig.json";
import plugins from "kothing-editor/lib/plugins";
import lang from "kothing-editor/lib/lang";
import "kothing-editor/dist/css/kothing-editor.min.css";

export default Vue.extend({
  name: "Edit",

  data: () => ({
    kothingConfig,
    editor: "" as any,
    editor2: "" as any,
    editor3: "" as any,
    subjects: {} as any,
    editorRequirements: [] as Array<string>,
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.emailTemplates().getForEdit(Number(to.params.id));
      const variables = await API.emailTemplates().getVariables();

      next(vm => {
        vm.editorRequirements = variables;
        vm.item = item;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  mounted() {
    this.createEditor();
    this.setEditorsContent();
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if (
          this.contains(this.editor.getContents(), this.editorRequirements) &&
          this.contains(this.editor2.getContents(), this.editorRequirements) &&
          this.contains(this.editor3.getContents(), this.editorRequirements)
        ) {
          this.setNewEditorContent();
          await API.emailTemplates().edit(
            Number(this.$route.params.id),
            this.item
          );
          await this.$router.go(-1);
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        } else {
          await this.$store.dispatch(
            "alert/showError",
            "Did not pass validation"
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    createEditor(): void {
      const language = this.$store.getters["localization/getCurrent"];
      this.editor = KothingEditor.create("editor", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
      this.editor2 = KothingEditor.create("editor2", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
      this.editor3 = KothingEditor.create("editor3", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
    },
    contains(target: any, pattern: Array<string>): boolean {
      for (const item of pattern) {
        if (!target.includes(item)) {
          return false;
        }
      }
      return true;
    },
    setEditorsContent(): void {
      this.editor.setContents(this.item.content_ru);
      this.editor2.setContents(this.item.content_ro);
      this.editor3.setContents(this.item.content_en);
      this.subjects.en = this.item.subject_en;
      this.subjects.ru = this.item.subject_ru;
      this.subjects.ro = this.item.subject_ro;
    },
    setNewEditorContent(): void {
      this.item.content_ru = this.editor.getContents();
      this.item.content_ro = this.editor2.getContents();
      this.item.content_en = this.editor3.getContents();
    }
  }
});
